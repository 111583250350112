<template>
  <div class="text-center">
    <v-snackbar
      class="margin-bottom"
      v-model="snackbar"
      :timeout="timeout"
      @input="emitSnackbarTimeout"
      :color="color"
    >
      <span class="white--text">{{ snackbarText }}</span>
      <!-- <v-btn
        color="white"
        text
        @click="emitSnackbarTimeout"
      >
        Close
      </v-btn> -->
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="emitSnackbarTimeout"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  export default {
    props: {
      snackbarText: String,
      color: String,
      snackbar: Boolean,
    },
    data: () => ({
      text: 'My timeout is set to 2000.',
      timeout: 2000,
    }),
    methods: {
      emitSnackbarTimeout() {
        this.$emit('emitSnackbarTimeout')
      }
    }
  }
</script>

<style lang="css">
.margin-bottom {
  margin-bottom: 100px;
}
</style>