<template>
  <v-dialog
    v-model="sendEmailDialog"
    max-width="900" 
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="dialog-transition"
    scrollable
  >
    <v-card>
      <v-card-title class="secondary white--text mb-5">
        Compose New Email
        <v-spacer></v-spacer>
        <v-icon color="white" @click="closeSendEmailDialog">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <!-- Select logged in user email account -->
        <v-row>
          <v-col cols="12" sm="6">
            <v-select
              v-model="selectedEmailAccount"
              color="gren"
              :background-color="confirmSelectBgColor"
              :items="this.$store.state.loggedInUserEmailAccounts"
              item-text="EmailAddress"
              return-object
              label="Select your email account."
              outlined
              dense
              @change="checkForDuplicatedEmailAccount"
            ></v-select>
          </v-col>
        </v-row>
        <!-- If email account is selected, show reply component  -->
        <div v-if="isEmailAccountSelected" class="reply-wrapper">
          <v-row>
            <!-- toEmail field -->
            <v-col class="py-0" cols="12">
              <v-combobox
                color="green"
                v-model="toEmails"
                label="To:"
                multiple
                chips
              >
                <template v-slot:selection="data">
                  <v-chip
                    small
                    :key="JSON.stringify(data.item)"
                    v-bind="data.attrs"
                    @click:close="data.parent.selectItem(data.item)"
                    :color="validateEmailColor(data.item)"
                  >
                    <v-avatar
                      class="accent white--text"
                      left
                      v-text="data.item.slice(0, 1).toUpperCase()"
                    ></v-avatar>
                    {{ data.item }}
                    <v-icon small right @click="data.parent.selectItem(data.item)">mdi-close</v-icon>
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
            <!-- CC email field -->
            <v-col class="py-0" v-if="ccEmails.length || showCc" cols="12">
              <v-combobox
                color="green"
                v-model="ccEmails"
                label="Cc:"
                multiple
                chips
              >
                <template v-slot:selection="data">
                  <v-chip
                    small
                    :key="JSON.stringify(data.item)"
                    v-bind="data.attrs"
                    @click:close="data.parent.selectItem(data.item)"
                    :color="validateEmailColor(data.item)"
                  >
                    <v-avatar
                      class="accent white--text"
                      left
                      v-text="data.item.slice(0, 1).toUpperCase()"
                    ></v-avatar>
                    {{ data.item }}
                    <v-icon small right @click="data.parent.selectItem(data.item)">mdi-close</v-icon>
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
          <!-- Bcc email field -->
            <v-col class="py-0" v-if="bccEmails.length || showBcc" cols="12">
              <v-combobox
                color="green"
                v-model="bccEmails"
                label="Bcc:"
                multiple
                chips
              >
                <template v-slot:selection="data">
                  <v-chip
                    small
                    :key="JSON.stringify(data.item)"
                    v-bind="data.attrs"
                    @click:close="data.parent.selectItem(data.item)"
                    :color="validateEmailColor(data.item)"
                  >
                    <v-avatar
                      class="accent white--text"
                      left
                      v-text="data.item.slice(0, 1).toUpperCase()"
                    ></v-avatar>
                    {{ data.item }}
                    <v-icon small right @click="data.parent.selectItem(data.item)">mdi-close</v-icon>

                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
          <div class="d-flex justify-end">
            <v-btn small class="px-0" text @click="showCc = !showCc">
              <span v-if="!showCc" class="grey--text text-capitalize">Cc</span>
              <span v-else class="grey--text text-capitalize">Hide Cc</span>
            </v-btn>
            <v-btn small class="px-0" text @click="showBcc = !showBcc">
              <span v-if="!showBcc" class="grey--text text-capitalize">Bcc</span>
              <span v-else class="grey--text text-capitalize">Hide Bcc</span>
            </v-btn>
          </div>
          <!-- {{messageInfo.Body}} -->

          <v-text-field
            color="green"
            v-model="subject"
            label="Subject"
          ></v-text-field>

          <!-- Quill text editor -->
          <quill-editor
            ref="myQuillEditor"
            v-model="content"
            :options="editorOption"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
          />

          <v-row>
            <v-col cols="12">
              <v-alert 
                v-model="alert"
                type="error"
                color="red"
                dismissible
              >
                Please add a valid email to the "To" field.
              </v-alert>
            </v-col>
            <v-col cols="6" sm="3">
              <span class="red--text" v-if="errorMessages">Invalid email!</span>
              <!-- <v-btn block :disabled="!toEmails.length"  @click="validateEmail" color="blue" class="white--text">Send</v-btn> -->
              <v-btn block :disabled="validateEmail"  @click="sendEmail" color="blue" class="white--text">Send</v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

var toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  // ['blockquote', 'code-block'],

  // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
  // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
  // [{ 'direction': 'rtl' }],                         // text direction

  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  [{ 'font': [] }],
  [{ 'align': [] }],

  ['image'],

  ['clean'],                                         // remove formatting button
  
];

export default {
  name: 'send-email',
  components: {
    quillEditor
  },
  props: {
    sendEmailDialog: Boolean,
  },
  data() {
    return {
      alert: false,
      content: '',
      editorOption: {
        // Some Quill options...
        theme: 'snow',
        modules: {
          toolbar: toolbarOptions
        }
      },
      subject: '',
      toEmailInput: '',
      toEmails: [],
      ccEmails: [],
      bccEmails: [],
      selectedEmailAccount: this.$store.state.loggedInUserEmailAccounts[0],
      showCc: false,
      showBcc: false,
      valid: true,
      errorMessages: false
    }
  },
  methods: {
    emailValidator(email) {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(email)
    },
    validateEmailColor(value) {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if(pattern.test(value)) { 
        return 'grey lighten-2'
      }
        return 'red'
    },
    closeSendEmailDialog() {
      this.subject = ''
      this.content = ''
      this.toEmails = []
      this.ccEmails = []
      this.bccEmails = []
      this.$emit('closeSendEmailDialog') 
    },
    sendEmail() {
      if(!this.toEmails.length) {
        //alert no toEmails
        this.alert = true
      }
      else {
        const emailDetails = {
          messagingEmailAccountID: this.selectedEmailAccount.OID,
          subject: this.subject,
          content: this.content,
          to: this.toEmails,
          cc: this.ccEmails,
          bcc: this.bccEmails 
        } 

        this.$store.dispatch('Messages/sendEmail', emailDetails)
        .then( () => {
          console.log('email sent')
          this.closeSendEmailDialog()                
          this.$emit('confirmEmailSend')
        })
        .catch( (err) => {
          console.log(err)
          this.closeSendEmailDialog()
          this.$emit('emailSendError')
        }) 
      }
    },
 
    //Quill methods
    onEditorBlur(quill) {
      console.log('editor blur!', quill)
    },
    onEditorFocus(quill) {
      console.log('editor focus!', quill)
    },
    onEditorReady(quill) {
      console.log('editor ready!', quill)
    },
    onEditorChange({ quill, html, text }) {
      console.log('editor change!', quill, html, text)
      this.content = html
    },
    checkForDuplicatedEmailAccount(item) { //check toEmails for duplicate email account and remove if TRUE
      // console.log(item)
      const array = this.toEmails
      let index = array.indexOf(item.EmailAddress)
      if(index > -1) {
        array.splice(index, 1)
      }
    },
  },
  computed: {
    validateEmail() {
      if(this.toEmails.every(this.emailValidator) && this.ccEmails.every(this.emailValidator) && this.bccEmails.every(this.emailValidator)) {
        // console.log('no errors')
        // this.errorMessages = false
        // this.sendEmail()
        return false
      } else {
        // console.log('invalid emails')
        // this.errorMessages = true
        return true
      }
    },
    editor() {
      return this.$refs.myQuillEditor.quill
    },
    isEmailAccountSelected() {
      if(this.selectedEmailAccount) {
        return true
      }
      return false
    },
    confirmSelectBgColor() {
      if(this.selectedEmailAccount) {
        return ''
      }
      return 'orange lighten-2'
    }
  },

}
</script>

<style>
.ql-editor{
  min-height:200px;
} 
.v-messages__message {
  color: red;
}
</style>