<template>
  <BranchEmails />
</template>

<script>
import BranchEmails from '@/components/MessageComponents/BranchEmails.vue';


export default {
  name: 'branch-emails-view',
  components: {
    BranchEmails
  }
  ,
  created(){
    this.$store.commit("EXPIRE_LOGIN");
    if(this.$store.state.user === ''){
      this.$router.push('/');
    }
  }
}
</script>

<style>

</style>