<template>
  <v-sheet v-if="selectedMessage" height="200px">
    <v-btn
      class="mt-6"
      text
      color="red"
      @click="toggleAttachmentSheet"
    >close</v-btn>
    <div class="py-3 pl-5" v-for="attachment in selectedMessage.Attachments" :key="attachment.OID">
      <v-btn @click="downloadAttachment(attachment)" color="success" class="custom-transform-class text-none">
        {{attachment.Name}}
        <v-icon right small>mdi-download</v-icon>
      </v-btn>
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: 'attachments-bottom-sheet',
  props: {
    attachmentSheet: Boolean,
    selectedMessage: Object,
  },
  methods: {
    toggleAttachmentSheet() {
      this.$emit('toggleAttachmentSheet')
    },
    downloadAttachment(attachment) {
      this.$store.dispatch('downloadMessageAttachment', attachment.OID)
      .then((response) => {
        console.log(response)
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', `${attachment.Name}`)
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    },
  }
}
</script>

<style>

</style>