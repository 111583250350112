<template>
  <v-card v-if="selectedMessage">
    <v-card-title class="secondary mb-8">
      <v-icon color="white" class="mr-5" @click="closeMsgDialog">mdi-arrow-left</v-icon>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            color="white"
          >
            <v-icon color="secondary">mdi-reply</v-icon>
            <span class="secondary--text caption">Reply</span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="replyToMsgDialog">
            <v-list-item-title>Reply via app</v-list-item-title>
          </v-list-item>
          <v-list-item @click="openNativeEmail">
            <v-list-item-title >Reply</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-card-subtitle>
      <!-- {{selectedMessage}} -->
      <div class="d-flex align-center">
        <span class="headline mr-auto">{{selectedMessage.Subject}}</span>
        <v-btn :fab="$vuetify.breakpoint.xsOnly" color="success" @click="toggleAttachmentSheet" v-if="selectedMessage.Attachments.length">
          <v-icon :left="$vuetify.breakpoint.smAndUp">mdi-attachment</v-icon>
          <span class="d-none d-sm-flex">Attachments</span>
        </v-btn>
      </div>
      <span class="font-weight-bold">{{ selectedMessage.Sender.AccountName }}</span> <span> &#60;{{ selectedMessage.Sender.EmailAddress }}&#62;</span>
      <div v-if="this.selectedMessage.CcAccounts.length" class="mt-5 font-weight-light"> 
        Cc: <span v-for="item in this.selectedMessage.CcAccounts" :key="item.EmailAddress">{{item.EmailAddress}}; </span>
      </div>
      <div v-if="this.selectedMessage.BccAccounts.length" class="font-weight-light"> 
        Bcc: <span v-for="item in this.selectedMessage.BccAccounts" :key="item.EmailAddress">{{item.EmailAddress}}; </span>
      </div>
    </v-card-subtitle>
    <v-card-text class="mt-10">
      <v-card flat>
        <p v-html="selectedMessage.Body"></p>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'selected-email-message',
  props: {
    selectedMessage: Object
  },
  methods: {
    closeMsgDialog() {
      this.$emit('closeMsgDialog')
    },
    replyToMsgDialog() {
      this.$emit('replyToMsgDialog')
    },
    toggleAttachmentSheet() {
      this.$emit('toggleAttachmentSheet')
    },
    openNativeEmail() {
      const emailTo = this.selectedMessage.Sender.EmailAddress
      const subject = this.selectedMessage.Subject
      let ccAccounts = this.selectedMessage.CcAccounts.map(item => {
        return item.EmailAddress
      })
      let bccAccounts = this.selectedMessage.BccAccounts.map(item => {
        return item.EmailAddress
      })

      window.open('mailto:'+emailTo+'?subject='+subject+'&cc='+ccAccounts+'&cc='+bccAccounts, '_blank');
    },
  }
}
</script>

<style>

</style>